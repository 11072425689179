<template>
  <div id="school-standards">
    <b-container class="base-container-x">
      <div v-if="loading === true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div v-if="loading === false" id="banner" class="banner-inner ayp">
        <!-- <div class="slideshow_caption">
              <div class="slideshow_inner_caption">
                <div class="slideshow_align_caption">
                  <h1 class="section-header-title text-uppercase">{{ $t('EYEP') }}</h1>
                  <h3 class="article-content">{{ $t('Exchange Yoga Experience Program (WYA Continuous Yoga Education)') }}</h3>
                </div>
              </div>
            </div> -->
      </div>
      <div v-if="loading === true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br />
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading === false" class="col-12 mt-4 mb-5 pt-4">
        <div class="mb-lg-5">
          <h2 class="text-center d-none d-lg-block">
            {{
              $t("World class High Standards for International Yoga Community")
            }}
          </h2>
        </div>
        <div class="introduction">
          <h3 class="mt-lg-5 mb-3 text-center">
            {{ $t("Become a WYA-Approved Yoga Professional") }}
          </h3>
          <p>
            {{
              $t(
                "AYP is a certification service that recognizes yoga practitioners who meet the rigorous standards of continuous learning and professional development set by the World Yoga Alliance. By becoming AYP-certified, ​your students can proudly demonstrate their commitment to advancing their yoga practice and teaching skills."
              )
            }}
          </p>
        </div>
        <div class="introduction">
          <div class="d-lg-flex align-items-center">
            <div class="left-img">
              <b-img
                class="img-fluid"
                :src="PUBLIC_PATH + 'img/certificate/AYP.png'"
              />
            </div>
            <div>
              <h4 class="mt-5 mb-3">
                {{ $t("What is ​the WYA-Approved Yoga Professional (AYP)? ") }}
              </h4>
              <p>
                {{
                  $t(
                    "The World Yoga Alliance is proud to announce a new initiative to elevate the global yoga education standard: the WYA Approved Yoga Professional (AYP) certification. This exclusive service empowers our registered yoga school and teacher members by providing an opportunity to offer additional professional support to their students."
                  )
                }}
              </p>
              <h4>{{ $t("Eligibility") }}</h4>
              <p>
                {{
                  $t(
                    "All Registered Yoga Schools (RSY), Online Yoga Course providers (OYCP), Register Yoga Teacher (RTY), Experienced Registered Yoga Teachers (E-RTY), and Certified Yoga Teachers (CYT) are eligible to obtain the AYP credential for their students.​ "
                  )
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="introduction">
          <div class="d-lg-flex align-items-center">
            <div class="left-img">
              <b-img
                class="img-fluid"
                :src="PUBLIC_PATH + 'img/certificate/CYP.png'"
              />
            </div>
            <div>
              <h4 class="mt-5 mb-3">
                {{ $t("Benefits of the AYP License") }}
              </h4>
              <ul>
                <li>
                  {{
                    $t(
                      "As a community member, this service enables you to support your students beyond their initial training and reflects your commitment to their growth as yoga professionals."
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "Enhanced Student Experience: Offer your students a prestigious global certification that adds value to their yoga journey."
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "Continuous Education Standards: Encourage lifelong learning and uphold high teaching standards by aligning your course with WYA's recognized continues education standard framework."
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "Support: Help your students gain a competitive edge in the yoga community through WYA's international recognition and support."
                    )
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="introduction">
          <h3>{{ $t("AYP License Highlight") }}</h3>
          <ol>
            <li>
              <strong>Global Recognition:</strong> Issue an international
              recognition certification as a Certified Yoga Professional (CYP
              License Logo) through the World Yoga Alliance.
            </li>
            <li>
              <strong>Professional Development:</strong> Demonstrate your
              commitment to continuous learning and maintaining high standards
              in yoga practice and teaching.
            </li>
            <li>
              <strong>Career Advancement:</strong> Stand out in the competitive
              yoga industry with a certification that reflects your dedication
              to professional growth.
            </li>
            <li>
              <strong>Affordable Investment:</strong> Obtain a globally
              recognized certification for an annual fee or apply for a lifetime
              license.
            </li>
            <li>
              <strong>Connection to Excellence:</strong> Align yourself with
              WYA's high standards of yoga education and be part of a global
              network of WYA-certified yoga professionals and educators.
            </li>
            <li>
              <strong>Lifelong Learning:</strong> Showcase your dedication to
              lifelong learning by attaining a certification that emphasizes
              continuous education.
            </li>
            <li>
              <strong
                >Become a Continuing Education Provider (C.E Standard)</strong
              >
              and offer ongoing educational support to your students. This
              credential is designed for professionals who share their expertise
              with the community through continuing education courses,
              workshops, and advanced learning opportunities.
            </li>
          </ol>
          <!-- <div
            class="my-5 d-flex justify-content-center flex-column flex-lg-row align-items-center"
            style="gap: 20px"
          >
            <b-img
              class="badge-img"
              :src="PUBLIC_PATH + 'img/certificate/WYA.png'"
            />
            <b-img
              class="badge-img"
              :src="PUBLIC_PATH + 'img/certificate/AYP.png'"
            />
            <b-img
              class="badge-img"
              :src="PUBLIC_PATH + 'img/certificate/CYP.png'"
            />
          </div> -->
        </div>
        <div class="certificate-sample">
          <div class="d-lg-flex">
            <div class="d-none d-lg-block left-img">
              <b-img
                class="img-fluid"
                :src="PUBLIC_PATH + 'img/sample/AYP_CYP.png'"
              />
              <h5 class="text-center mt-3" style="font-size: 1.15rem">
                {{ $t("Sample License for School") }}
              </h5>
            </div>
            <div>
              <h4 class="mb-3">
                {{ $t("How School can Issue the CYP License:") }}
              </h4>
              <p>
                {{
                  $t(
                    "Once you have obtained the AYP License, you become eligible to issue the CYP License. You can create your own certificates featuring the WYA official logo, the CYP logo, and a unique CYP ID number. This ID is searchable in our International Yoga Teachers Directory, and the CYP profile will be displayed under the Graduates tab in your main profile."
                  )
                }}
              </p>
              <p>
                {{
                  $t(
                    "Please Note: Members are able to apply CYP ID Number directly from the WYA Account."
                  )
                }}
              </p>
              <div class="d-block d-lg-none">
                <b-img
                  class="img-fluid"
                  :src="PUBLIC_PATH + 'img/sample/AYP_CYP.png'"
                />
                <h5 class="text-center mt-3" style="font-size: 1.15rem">
                  {{ $t("Sample License for School") }}
                </h5>
              </div>
              <div class="d-lg-flex mt-4 sample-certificate">
                <div class="flex-grow-0 w-lg-50">
                  <b-img
                    class="img-fluid"
                    :src="PUBLIC_PATH + 'img/sample/AYP_CYP_student_1.png'"
                  />
                  <div
                    class="mb-5 d-flex justify-content-center align-items-center mt-4"
                    style="gap: 10px"
                  >
                    <b-img
                      width="100"
                      class="img-fluid"
                      :src="PUBLIC_PATH + 'img/certificate/WYA.png'"
                    />
                    <b-img
                      width="100"
                      class="img-fluid"
                      :src="PUBLIC_PATH + 'img/certificate/AYP.png'"
                    />
                    <b-img
                      width="100"
                      class="img-fluid"
                      :src="PUBLIC_PATH + 'img/certificate/CYP.png'"
                    />
                  </div>
                </div>
                <div class="flex-grow-1 w-lg-50">
                  <b-img
                    class="img-fluid"
                    :src="PUBLIC_PATH + 'img/sample/AYP_CYP_student_2.png'"
                  />
                </div>
              </div>
              <h5 class="mt-4 text-center" style="font-size: 1.15rem">
                {{ $t("Sample Certificate from School") }}
              </h5>
            </div>
          </div>
        </div>
        <div class="centered-box">
          <h3>{{ $t("AYP License Fee") }}</h3>
          <p>
            {{ $t("You must be a") }}
            <strong>{{ $t("current Registered or Certified Member") }}</strong>
            {{ $t("to apply for the") }}
            <strong>{{
              $t("World Yoga Alliance Approved Yoga Professional")
            }}</strong>
            {{ $t("credential.") }}
          </p>
        </div>
        <div class="introduction">
          <h3>{{ $t("Due:") }}</h3>
          <div class="payment-box">
            <div>
              <div>{{ $t("Annual License Fee") }}</div>
              <div>USD 21 $ (EUR 21 €)</div>
            </div>
            <div>
              <div>{{ $t("Lifetime License Fee") }}</div>
              <div>USD 169 $ (EUR 169 €)</div>
            </div>
          </div>
        </div>
        <div class="centered-box">
          <h3>{{ $t("Become an AYP Authorized") }}</h3>
          <p>
            {{
              $t(
                "Let's work together to inspire growth, professionalism, and excellence in the yoga world. Empower your students' yoga journey today with AYP License."
              )
            }}
          </p>
          <div class="mt-4">
            <a
              href="mailto:contact@wyayoga.org?subject=Apply%20for%20AYP%2FCYP&body=Name:%20%0AEmail:%20%0AMobile:%20%0ACountry:%20%0AWYA%20Member%20ID:%20%0A%0ARegistration%20Type:%20(lifetime%20or%20annual)"
            >
              <b-button class="apply-now-button" variant="primary">
                {{ $t("Apply Now") }}
              </b-button>
            </a>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>
  
  <script>
export default {
  name: "ChildrenYogaSchool",
  title: " | Children's Yoga School",
  data() {
    return {
      loading: false,
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.toggleBurgerButtonClass("addClass", "bars-bg-gray");
    this.loading = false;
  },
  methods: {
    scrollToElement(id) {
      const element = document.getElementById(id);
      const elementOffset = element.offsetTop - 80;
      console.log("elementOffset", elementOffset);
      window.scroll({ top: elementOffset, behavior: "smooth" });
    },
    toggleBurgerButtonClass(addRemoveClass, className) {
      const burger = document.querySelector(".bm-burger-button");
      if (addRemoveClass === "addClass") {
        burger.classList.add(className);
      } else {
        burger.classList.remove(className);
      }
    },
  },
  destroyed() {
    this.toggleBurgerButtonClass("removeClass", "bars-bg-gray");
  },
};
</script>
  
  <style scoped>
#school-standards {
  /* Lock Text */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
#school-standards h3,
#school-standards h4 {
  margin-bottom: 1rem;
}
#school-standards li {
  margin-bottom: 1rem;
}
.levels-table td:hover,
.introduction li:hover,
.introduction strong:hover {
  cursor: pointer;
}
.centered-box {
  text-align: center;
  padding: 20px 50px 20px;
  border: 1px solid #f04b4c !important;
  margin: 50px auto;
}
.badge-img {
  width: 200px;
}
.payment-box {
  background-color: #4472c4;
  color: white;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.payment-box > div:first-child {
  border-right: 1px solid white;
  width: calc(50% - 1px);
  padding: 20px 20px 0;
}
.payment-box > div:last-child {
  flex: 1 1 auto;
  padding: 20px;
}
.apply-now-button {
  background-color: #f04b4c !important;
  border-color: #f04b4c !important;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}
.apply-now-button:hover,
.apply-now-button:focus {
  background-color: #f04b4c !important;
  border-color: #f04b4c !important;
  color: white;
  outline: none;
}
.left-img {
  flex: 0 0 30%;
  padding-right: 20px;
}
.sample-certificate {
  gap: 20px;
  margin-left: auto;
  width: 90%;
}
@media (max-width: 767.98px) {
  .left-img {
    float: none;
  }
  .banner-inner {
    min-height: 128px;
  }
  .payment-box {
    flex-direction: column;
  }
  .payment-box > div:first-child {
    border-right: none;
    border-bottom: 1px solid white;
    width: 100%;
    padding-bottom: 20px;
  }
  .sample-certificate {
    width: 100%;
  }
}
</style>
  